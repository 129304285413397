<script setup lang="ts">
import { HomeJackpots, Game } from "~/types";

const DEFAULT_INCREMENT_STEP = 0.15;
const DEFAULT_INCREMENT_INTERVAL = 550;

const props = defineProps<{
	gameMode?: string;
	jackpots?: HomeJackpots;
	games: Game[];
}>();

const { t } = useT();
const prepareImgUrl = useImage();
const { open } = useAppModals();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });
const { handleOpenGame } = useOpenGame(open);
const { data: appInitData } = useAppInitData();

const intervalId = ref<ReturnType<typeof setInterval>>();
const amount = ref(0);
const arraybleAmount = ref<string[]>(["0"]);

const isSweepStakes = computed(() => props.gameMode === "SweepStakes");
const totalWinAmount = computed(() => {
	const values = props.jackpots?.[isSweepStakes.value ? "USD" : "TRN"]?.values ?? [];
	return values.reduce((acc, item) => acc + (item.amount ?? 0), 0) * 100;
});

const incrementValue = () => {
	amount.value += DEFAULT_INCREMENT_STEP;
	if (isSweepStakes.value) {
		arraybleAmount.value = numberFormat(amount.value, { minimumFractionDigits: 2 }).replace(/\.\d*/, "").split("");
		return;
	}
	arraybleAmount.value = numberFormat(amount.value * 100).split("");
};

const handlePlayClick = (game: Game) => {
	if (!game.isOnlyForApp) {
		const lastGames = appInitData.value?.lastGames || [];
		if (lastGames.includes(game.id)) {
			appInitData.value?.lastGames &&
				(appInitData.value.lastGames = [game.id as number, ...lastGames.filter((el) => el !== game.id)]);
		} else {
			appInitData?.value?.lastGames && (appInitData.value.lastGames = [game.id as number, ...lastGames]);
		}
		handleOpenGame(game.slug);
	}
};

watch(isSweepStakes, () => {
	amount.value = totalWinAmount.value;
});

onMounted(() => {
	amount.value = totalWinAmount.value;
	intervalId.value = setInterval(incrementValue, DEFAULT_INCREMENT_INTERVAL);
});
onUnmounted(() => clearInterval(intervalId.value));
</script>

<template>
	<section :class="['jackpot', { 'is-logged': !appInitData?.isGuest }]">
		<picture>
			<source
				:srcset="prepareImgUrl('/nuxt-img/jackpot/title-mob@2x.png', { format: 'webp', width: 656, height: 360 })"
				format="webp"
				width="656"
				height="360"
				media="(max-width: 767px)"
			/>
			<NuxtImg
				src="/nuxt-img/jackpot/title.png"
				format="webp"
				width="364"
				height="214"
				class="header"
				alt="title"
				loading="lazy"
			/>
		</picture>
		<div class="img-holder-left">
			<NuxtImg
				src="/nuxt-img/jackpot/leaves-left.png"
				format="webp"
				width="457"
				height="425"
				class="leaves-left"
				alt="leaves"
				loading="lazy"
			/>
		</div>
		<div class="img-holder-right">
			<NuxtImg
				src="/nuxt-img/jackpot/leaves-right.png"
				format="webp"
				width="429"
				height="425"
				class="leaves-right"
				alt="leaves"
				loading="lazy"
			/>
		</div>
		<div class="values">
			<NuxtImg
				src="/nuxt-img/jackpot/icon.png"
				format="webp"
				width="65"
				height="64"
				class="icon"
				alt="icon"
				loading="lazy"
			/>
			<AText variant="tabuk" :modifiers="['bold', 'center', 'nowrap']" class="amount" as="div">
				<TransitionGroup name="counter">
					<span
						v-for="(item, index) in arraybleAmount"
						:key="item + index"
						:class="['amount-item', { 'is-separator': isNaN(parseInt(item)) }]"
					>
						{{ isNaN(parseInt(item)) ? "," : item }}
					</span>
				</TransitionGroup>
			</AText>
		</div>
		<div class="content">
			<div class="title">
				<AText variant="tanta" :modifiers="['bold']">{{ t("Jackpot Games") }}</AText>
				<NuxtLink to="/issues/jackpot/">
					<AText variant="toledo" :modifiers="['link', 'underline']">{{ t("Show all") }}</AText>
				</NuxtLink>
			</div>
			<div v-if="games.length" class="games">
				<MGame
					v-for="game in games"
					:key="game.id"
					:game="game"
					:last-games="appInitData?.lastGames || []"
					class="game"
					@toggle-favorite="handleToggleToFavoriteClick(game)"
					@play="handlePlayClick(game)"
				/>
			</div>
		</div>
	</section>
</template>

<style scoped lang="scss">
.counter {
	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
	}

	&-leave-active {
		position: absolute;
	}

	&-leave-to {
		transform: translateY(-40px);
	}
}
.jackpot {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: gutter(3);
	padding-top: gutter(13.5);
	margin: gutter(11.5) 0 gutter(3);
	background: var(--chicago);
	border-radius: 16px;

	&.is-logged {
		margin-bottom: gutter(5);
	}

	@include media-breakpoint-down(lg) {
		padding: gutter(11.25) gutter(1.25) gutter(2);
		margin: gutter(13.25) gutter(2) gutter(2);

		&.is-logged {
			margin-bottom: gutter(5);
		}
	}

	.header {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, -68px);

		@include media-breakpoint-down(md) {
			transform: translate(-50%, -50%);
			width: 328px;
			height: 180px;
		}
	}

	.leaves-left,
	.leaves-right {
		@include media-breakpoint-down(xl) {
			display: none;
		}
	}

	.img-holder-left,
	.img-holder-right {
		position: absolute;
		top: 0;
		bottom: 0;
		overflow: hidden;
	}

	.img-holder-left {
		left: 0;
		border-radius: 16px 0 0 16px;
	}

	.img-holder-right {
		right: 0;
		border-radius: 0 16px 16px 0;
	}

	.leaves-left {
		animation: 10s rotate-animation infinite ease-in-out;
	}

	.leaves-right {
		animation: 10s rotate-animation-right infinite ease-in-out;
	}

	.values {
		display: flex;
		gap: gutter(1);
		align-items: center;

		@include media-breakpoint-down(md) {
			gap: gutter(0.5);
		}
	}

	.content {
		width: 720px;
		max-width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: gutter(2);
		z-index: 1;

		@include media-breakpoint-down(md) {
			margin-top: gutter(2);
		}
	}

	.title {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: var(--craiova);
	}

	.icon {
		@include media-breakpoint-down(md) {
			width: 32px;
			height: 32px;
		}
	}

	.link {
		color: var(--craiova);
	}

	.amount {
		position: relative;
		top: 4px;
		overflow: hidden;
		color: var(--crawley);
		line-height: 64px;

		@include media-breakpoint-down(md) {
			font-size: 32px;
			line-height: 40px;
		}

		&-item:not(.is-separator) {
			transition: all 0.55s ease;
			display: inline-block;
			width: 35px;

			@include media-breakpoint-down(md) {
				width: 20px;
			}
		}
	}

	.games {
		display: grid;
		grid-template-columns: repeat(4, 168px);
		gap: gutter(2);

		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(2, 150px);
			gap: gutter(1);

			.game {
				&:nth-last-child(-n + 2) {
					display: none;
				}
			}

			@media (orientation: landscape) {
				grid-template-columns: repeat(4, 168px);

				.game {
					&:nth-last-child(-n + 2) {
						display: block;
					}
				}
			}
		}
	}
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0);
	}

	50% {
		transform: rotate(2deg) scale(1.07);
	}

	100% {
		transform: rotate(0);
	}
}

@keyframes rotate-animation-right {
	0% {
		transform: rotate(0);
	}

	50% {
		transform: rotate(-2deg) scale(1.07);
	}

	100% {
		transform: rotate(0);
	}
}
</style>
